<template>
  <x-dialog :proxy="addDialog" class="addkuang">
    <div class="mt-3"></div>

    <el-form class="form1" v-if="sizeForm.num === 1" ref="form" :model="sizeForm" :rules="editRules" label-width="80px">
      <el-form-item v-if="!sizeForm.member_id" label="添加方式">
        <el-radio-group v-model="selectType" @input="changeType">
          <el-radio :label="1">手机号</el-radio>
          <el-radio :label="2">账号密码</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="member_name" label="用户姓名">
        <el-input class="ipt" maxlength="20" v-model.trim="sizeForm.member_name"></el-input>
      </el-form-item>
      <el-form-item :prop="!!sizeForm.member_id && sizeForm.login_account !== '' ? '' : 'mobile'"
        v-if="!!sizeForm.member_id || selectType == 1" label="手机号码">
        <el-input class="ipt"
          :disabled="rowparms.member_id && rowparms.mobile != '' && rowparms.join_way !== 6 || (rowparms.join_way == 6 && rowparms.update_virtually_mobile !== 0)"
          v-model.number="sizeForm.mobile"></el-input>
      </el-form-item>

      <el-form-item :prop="!!sizeForm.member_id && sizeForm.mobile !== '' ? '' : 'login_account'"
        v-if="!!sizeForm.member_id || selectType == 2" label="登陆账号">
        <el-input class="ipt" v-model.trim="sizeForm.login_account"
          :disabled="rowparms.member_id && rowparms.login_account != null && rowparms.login_account !== ''"></el-input>
      </el-form-item>
      <el-form-item prop="password" v-if="!!sizeForm.member_id || selectType == 2" label="登陆密码">
        <el-input placeholder="请输入密码" v-model.trim="sizeForm.password" show-password>
        </el-input>
        <div>
          <p>提示：</p>
          <p>1.账号由2~16位汉字、字母、数字组成，可纯汉字、纯字母、纯数字，字母不区分大小写。</p>
          <p>2.密码由6~16位字母、数字组成，可纯字母、纯数字，字母区分大小写。</p>
        </div>
      </el-form-item>
      <el-form-item v-if="!!sizeForm.member_id" prop="enable_money" label="账户余额">
        <el-input class="ipt" :disabled="!!sizeForm.member_id" v-model.trim="sizeForm.enable_money"></el-input>
      </el-form-item>

      <el-form-item label="用户状态">
        <el-radio-group v-model="sizeForm.disabled">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="-1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="shop_self_goods_open" label="核销权限">
        <el-radio-group v-model="sizeForm.member_verify_permissions">
          <el-radio :label="0">无</el-radio>
          <el-radio :label="1">有</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="zt" label="分组">
        <el-select v-model="sizeForm.group_id" size="medium" placeholder="请设置用户分组" clearable>
          <el-option v-for="(item, index) in moremsg" :key="index" :label="item.group_name"
            :value="item.group_id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-form v-if="sizeForm.num === 4" class="form2" ref="form" :model="sizeForm">
      <el-form-item label="分组">
        <el-select v-model="sizeForm.group_id" size="medium" style="width: 300px" placeholder="请设置用户分组">
          <el-option v-for="(item, index) in moremsg" :key="index" :label="item.group_name"
            :value="item.group_id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </x-dialog>
</template>

<script>
import md5 from "js-md5";
import * as API_Users from '@/api/users';
import { RegExp } from '@/../ui-utils';
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import { getSellerShopExtAuth } from "@/api/account"
export default {
  name: 'add-member',
  components: { XDialog },
  data () {
    return {
      shop_self_goods_open: false,//是否开启商城自建开关
      selectType: 1,// 账号类型
      addDialog: $xDialog.create({
        width: '450px',
        beforeConfirm: () => {
          if (this.sizeForm.num === 1) {
            return this.onSubmit();
          } else {
            return this.changGroup();
          }
        }
      }),
      sizeForm: {
        member_name: '',
        mobile: '',
        login_account: '',// 登录账号
        password: '', // 登录密码
        enable_money: 0,
        disabled: 0,
        member_verify_permissions: 0,
        group_id: '',
      },
      rowparms: {},
      flag: false,
      moremsg: [],
      // 编辑 表单规则
      editRules: {
        member_name: [
          {
            required: true,
            message: '请输入用户姓名',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入用户姓名'));
              } else {
                callback();
              }
            }
          }
        ],
        enable_money: [
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('账户余额必须大于等于0'));
              } else if (value > 999999) {
                callback(new Error('账户余额必须小于等于999999'));
              } else if (!RegExp.money.test(value)) {
                callback(new Error('请输入正确的面值金额'));
              } else {
                callback();
              }
            }
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入用户电话',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机格式有误'));
              } else {
                callback();
              }
            }
          }
        ],
        login_account: [
          {
            required: true,
            message: '请输入登陆账号',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!/[a-zA-Z0-9\u4e00-\u9fa5]{2,16}/.test(value)) {
                callback(new Error('账号格式有误'));
              } else {
                callback();
              }
            }
          }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (value != '' && !/^[a-zA-Z0-9]{6,16}$/.test(value)) {
                callback(new Error('密码格式有误'));
              } else {
                callback();
              }
            }
          }
        ]
      }
    };
  },
  created () {
    getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id).then(res => {
      this.shop_self_goods_open = res.shop_self_goods_open === 'OPEN'// 商城自建商品功能
    })
  },
  methods: {
    changeType (data) {
      if (data == 1) this.sizeForm.login_account = "", this.sizeForm.password = ""
      else this.sizeForm.mobile = ""
    },
    show (params) {
      params.login_account == null ? params.login_account = '' : null
      this.rowparms = {
        ...params
      }
      this.sizeForm = params
      API_Users.getUserGroups().then(res => {
        this.moremsg = [
          {
            group_id: 0,
            group_name: '暂无'
          },
          ...res,
        ];
        let title;

        if (params.num === 1) {
          title = params.member_name ? '编辑用户' : '添加用户';
        } else {
          title = '设置用户分组';
        }

        this.addDialog.display({
          title,
        });
      });
    },
    onSubmit () {
      return new Promise(resolve => {
        // toLowerCase() 转换小写
        this.$refs['form'].validate(valid => {
          //判断新增账号登录||判断编辑账号有值密码为空
          if (valid) {
            if (this.sizeForm.password == '' || this.sizeForm.password == undefined) {
              if (this.rowparms.member_id) {//编辑
                //账户输入框是否被禁用
                let disabledAccount = this.rowparms.member_id && this.rowparms.login_account != null && this.rowparms.login_account !== ''
                if (!disabledAccount && this.sizeForm.login_account !== '') {//如果被账号没被禁用并且有值
                  this.$message.error('密码不能为空！');
                  return false
                }
              } else {//新增
                if (this.selectType == 2) {//账号注册
                  this.$message.error('密码不能为空！')
                  return false
                }
              }
            }
            let sizeForm = {
              ...this.sizeForm,
              login_account: this.sizeForm.login_account ? this.sizeForm.login_account.toLowerCase() : ''
            }
            if (sizeForm.password) sizeForm.password = md5(sizeForm.password);
            if (this.sizeForm.member_id) {
              //编辑
              if (this.shop_self_goods_open) {
                API_Users.putMemberVerify(this.sizeForm.member_id, { member_verify_permissions: this.sizeForm.member_verify_permissions })
              } else {
                delete sizeForm.member_verify_permissions
              }
              API_Users.changeMember(this.sizeForm.member_id, sizeForm).then(res => {
                this.$emit('refresh', res);
                resolve(true);
              });
            } else {
              sizeForm.add_type = this.selectType == 1 ? 0 : 1;
              API_Users.addMember(sizeForm).then(res => {
                this.$emit('refresh', res);
                resolve(true);
              });
            }
          } else {
            this.$message.error('表单填写有误，请检查！');
            resolve(false);
          }
        });
      });
    },
    changGroup () {
      return new Promise(resolve => {
        API_Users.changeGroup(this.sizeForm.ids, {
          group_id: this.sizeForm.group_id,
        }).then(res => {
          resolve(true);
        }).catch(() => {
          resolve(false);
        });
      });
    },
    cancel () {
      this.$emit('fromson', this.flag);
    }
  }
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: initial;
}

.el-form-item {
  margin-bottom: 14px;
}
</style>
